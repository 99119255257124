<template>
  <div id="main-wrapper" class="inner-pages acct-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{ $t("accounttypepage.title") }}</h2>
        <p>{{ $t("accounttypepage.tagline1") }}<br />{{ $t("accounttypepage.tagline2") }}</p>
      </div>
    </div>
    <div class="section acct-content">
      <div class="wrapper">
        <div class="top-content t-left">
          <p>{{ $t("accounttypepage.para1.desc1") }}</p>
          <ul class="listing-check">
            <li>{{ $t("accounttypepage.para1.point1") }}</li>
            <li>{{ $t("accounttypepage.para1.point2") }}</li>
            <li>{{ $t("accounttypepage.para1.point3") }}</li>
            <li>{{ $t("accounttypepage.para1.point4") }}</li>
            <li>{{ $t("accounttypepage.para1.point5") }}</li>
            <li>{{ $t("accounttypepage.para1.point6") }}</li>
          </ul>
          <p>{{ $t("accounttypepage.para1.desc2") }}</p>
        </div>

        <div class="section-small listing">
          <div class="account-holder wow fadeIn">
            <div class="title-holder">
              <h3>{{ $t("ecn") }}</h3>
            </div>
            <div class="info clearfix">
              <div class="left-col f-left">
                <p>{{ $t("spreads") }}<br />
                  ({{ $t("minpip") }})
                </p>
              </div>
              <div class="right-col f-right">
                <h3>0.0</h3>
              </div>
            </div>
            <div class="info clearfix">
              <div class="left-col f-left">
                <p>{{ $t("commission") }} <br />({{ $t("per") }} $100k)</p>
              </div>
              <div class="right-col f-right">
                <h3>$5</h3>
              </div>
            </div>
            <div class="info clearfix">
              <div class="left-col f-left">
                <p>{{ $t("leverage") }}<br />({{ $t("max") }}.)</p>
              </div>
              <div class="right-col f-right">
                <h3>30X</h3>
              </div>
            </div>
          </div>
          <div class="account-holder wow fadeIn" data-wow-delay="0.3s">
            <div class="title-holder">
              <h3>{{ $t("ecn") }}<span>{{ $t("plus") }} </span></h3>
            </div>
            <div class="info clearfix">
              <div class="left-col f-left">
                <p>{{ $t("spreads") }}<br />({{ $t("minpip") }})</p>
              </div>
              <div class="right-col f-right">
                <h3>0.0</h3>
              </div>
            </div>
            <div class="info clearfix">
              <div class="left-col f-left">
                <p>{{ $t("commission") }} <br />({{ $t("per") }} $100k)</p>
              </div>
              <div class="right-col f-right">
                <h3>$3</h3>
              </div>
            </div>
            <div class="info clearfix">
              <div class="left-col f-left">
                <p>{{ $t("leverage") }} <br />({{ $t("max") }}.)</p>
              </div>
              <div class="right-col f-right">
                <h3>30X</h3>
              </div>
            </div>
          </div>
          <div class="account-holder wow fadeIn" data-wow-delay="0.6s">
            <div class="title-holder">
              <h3>{{ $t("ecn") }}<span>{{ $t("zulu_trade") }}</span></h3>
            </div>
            <div class="info clearfix">
              <div class="left-col f-left">
                <p>{{ $t("spreads") }}<br />({{ $t("minpip") }})</p>
              </div>
              <div class="right-col f-right">
                <h3>0.0</h3>
              </div>
            </div>
            <div class="info clearfix">
              <div class="left-col f-left">
                <p>{{ $t("commission") }} <br />({{ $t("per") }} $100k)</p>
              </div>
              <div class="right-col f-right">
                <h3>$5</h3>
              </div>
            </div>
            <div class="info clearfix">
              <div class="left-col f-left">
                <p>{{ $t("leverage") }} <br />({{ $t("max") }}.)</p>
              </div>
              <div class="right-col f-right">
                <h3>30X</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="acct-desc forex-page section">
      <div class="wrapper">
        <div class="title-holder wow fadeIn">
          <h3>{{ $t("accounttypepage.accdesc.title") }}</h3>
        </div>
        <div class="accordion-holder t-left wow fadeIn">
          <h3
            v-on:click="ecnaccount ^= true"
            :class="ecnaccount ? 'active' : ''"
          >
          {{ $t("accounttypepage.accdesc.point1.title") }}
          </h3>
          <div class="content" v-show="ecnaccount">
            <p>{{ $t("accounttypepage.accdesc.point1.desc") }}</p>
          </div>
          <h3 v-on:click="ecnplus ^= true" :class="ecnplus ? 'active' : ''">
            {{ $t("accounttypepage.accdesc.point2.title") }}
          </h3>
          <div class="content" v-show="ecnplus">
            <p>{{ $t("accounttypepage.accdesc.point2.desc") }}</p>
          </div>
          <h3 v-on:click="ecnzulu ^= true" :class="ecnzulu ? 'active' : ''">
            {{ $t("accounttypepage.accdesc.point3.title") }}
          </h3>
          <div class="content" v-show="ecnzulu">
            <p>{{ $t("accounttypepage.accdesc.point3.desc") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="acct-content compare-accts section">
      <div class="wrapper">
        <div class="title-holder wow fadeIn">
          <h3>{{ $t("compairacc") }}</h3>
        </div>
        <div class="table-holder home-table compare-acct wow fadeIn">
          <table>
            <tr>
              <th>&nbsp;</th>
              <th>{{ $t("ecnacc") }}</th>
              <th>{{ $t("ecn") }} <span>{{ $t("plus") }}</span> {{ $t("account") }}</th>
              <th>{{ $t("ecn") }} <span>{{ $t("zulu_trade") }}</span> {{ $t("account") }}</th>
            </tr>
            <tr>
              <td>{{ $t("mindeposit") }}</td>
              <td>$10</td>
              <td>$10,000</td>
              <td>$300</td>
            </tr>
            <tr>
              <td>{{ $t("spread") }} ({{ $t("min") }}.)</td>
              <td>0.0</td>
              <td>0.0</td>
              <td>0.0</td>
            </tr>
            <tr>
              <td>{{ $t("leverage") }} ({{ $t("upto") }})</td>
              <td>30x</td>
              <td>30x</td>
              <td>30x</td>
            </tr>
            <tr>
              <td>{{ $t("basecurrency") }}</td>
              <td>{{ $t("usd") }} / {{ $t("eur") }} / CHF</td>
              <td>{{ $t("usd") }} / {{ $t("eur") }} / CHF</td>
              <td>{{ $t("usd") }} / {{ $t("eur") }} / CHF</td>
            </tr>
            <tr>
              <td>{{ $t("min") }}. 0.01 {{ $t("lot") }}</td>
              <td>{{ $t("yes") }}</td>
              <td>{{ $t("yes") }}</td>
              <td>{{ $t("yes") }}</td>
            </tr>
            <tr>
              <td>{{ $t("margincall") }}/{{ $t("stopout") }}</td>
              <td>100%/ 70%</td>
              <td>100%/ 70%</td>
              <td>100%/ 70%</td>
            </tr>
            <tr>
              <td>{{ $t("minorderdest") }}</td>
              <td>{{ $t("no") }}</td>
              <td>{{ $t("no") }}</td>
              <td>{{ $t("no") }}</td>
            </tr>
            <tr>
              <td>{{ $t("negativebalance") }}</td>
              <td>{{ $t("yes") }}</td>
              <td>{{ $t("yes") }}</td>
              <td>{{ $t("yes") }}</td>
            </tr>
            <tr>
              <td class="comm">{{ $t("commission") }}</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>{{ $t("forex") }} <span>({{ $t("per") }} $100K)</span></td>
              <td>5 $</td>
              <td>3 $</td>
              <td>5 $</td>
            </tr>
            <tr>
              <td>{{ $t("indices") }}<span>({{ $t("per") }} $100K)</span></td>
              <td>5 $</td>
              <td>3 $</td>
              <td>5 $</td>
            </tr>
            <tr>
              <td>{{ $t("commodities") }} <span>({{ $t("per") }} $100K)</span></td>
              <td>5 $</td>
              <td>3 $</td>
              <td>5 $</td>
            </tr>
            <tr>
              <td>{{ $t("stock") }} <span>({{ $t("per") }} {{ $t("lot") }})</span></td>
              <td>0,09%</td>
              <td>0,08%</td>
              <td>0,09%</td>
            </tr>
            <tr>
              <td>{{ $t("crypto") }} <span>({{ $t("per") }} {{ $t("lot") }})</span></td>
              <td>Price * 0.001</td>
              <td>Price * 0.0008</td>
              <td>Price * 0.001</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>
                <a :href="siteurl2" target="_blank" class="btn btn-llblue">{{ $t("openacc") }}</a>
              </td>
              <td>
                <a :href="siteurl2" target="_blank" class="btn btn-llblue">{{ $t("openacc") }}</a>
              </td>
              <td>
                <a :href="siteurl2" target="_blank" class="btn btn-llblue">{{ $t("openacc") }}</a>
              </td>
            </tr>
          </table>
        </div>
        <div class="title-holder t-left">
          <p>
            {{ $t("accounttypepage.accdesc.para1") }}
            <a href="mailto:support@aaafx.com">support@aaafx.com</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ecnaccount: true,
      ecnplus: false,
      ecnzulu: false,
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
};
</script>
